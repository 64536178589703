// TinyMCE templates

// image-grid-2x2:
.image-grid-2x2 {
    padding: 0;
    margin: 0 0 @grid-gutter-width 0;
    list-style: none;
    .make-row();

    > li {
        .make-xs-column(12);
        .make-sm-column(6);
        margin-top: @grid-gutter-width;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}


// AREZZO STYLES

// header
#content-header {
  background-color: @arezzo-color;
  border-bottom: 3px solid #FFF;
}
#portal-header {
    margin: 0 auto 0;
    padding-top: 0;
    margin-bottom: 0;
    background: @arezzo-color url(../images/header_dx.jpg) no-repeat right top;

    #portal-logo {
      width: 318px;
      overflow: hidden;
      display: inline-block;
      height: 156px;
    }
}

// search
#portal-header #portal-searchbox {
  margin: 60px 2% 0 2%;
  padding: 10px;
  background-color: rgba(255,255,255, .7);
  border-radius: 3px;
  width: auto;

  input.searchButton {
    background-color: @arezzo-red-color;
    color: #FFF;
    text-transform: uppercase;
    border-color: @arezzo-red-color;
    font-weight: bold;

    &:focus,
    &:hover {
      background-color: lighten(@arezzo-red-color, 10%);
      border-color: lighten(@arezzo-red-color, 10%);
    }
  }

  .searchSection {
    display: none;
  }
}

// breadcrumbs
#portal-breadcrumbs {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
}

// portlets
.portlet{
  ul li i.glyphicon {
    display: none;
  }
  .portletHeader {
    background-color: @arezzo-color;
    color: #FFF;

    a {
      color: #FFF;
    }
  }

  .portletContent {
    ul {
      li {
        a {
          padding: 10px 15px 10px;

          &:before {
            display: none;
          }

          &.contenttype-booking {
            &:before {
              display: none !important;
            }
          }
        }

        .portletItemDetails {
          margin: 0;
          padding: 0 15px 10px;
        }
      }
    }
  }

  ul.portletContent {
    padding: 0;
    list-style: none;
  }
}

// footer
#portal-footer-wrapper {
  .portlet .portletContent {

    > .glyphicon,
    > abbr {
      color: #FFF;
    }
  }
  .portletWrapper:first-child {
    border-color: #FFF;
  }
}

// label
.template-usergroup-userprefs .label{
  color: #4d4d4d;
}
